export const LogoWithText = ({
  topClassName = "",
  bottomClassName = "",
  className = "",
  children,
}: {
  className?: string;
  topClassName?: string;
  bottomClassName?: string;
  children?: JSX.Element;
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1760 314.03"
      enableBackground="new 0 0 1760 314.03"
      className={className}
      xmlSpace="preserve"
    >
      {children}
      <g className={topClassName}>
        <path
          d="M218.59,119.36h24.53l2.53,25.25h1.08c15.51-16.59,33.55-29.58,54.47-29.58c27.05,0,41.48,12.62,48.7,33.19
		c18.04-19.48,36.43-33.19,57.71-33.19c36.07,0,53.39,23.81,53.39,68.54v111.1h-29.94V187.53c0-32.83-10.46-46.53-32.46-46.53
		c-13.71,0-27.78,8.66-44.01,26.69v126.97h-29.94V187.53c0-32.83-10.1-46.53-32.46-46.53c-12.99,0-27.41,8.66-43.65,26.69v126.97
		h-29.94V119.36z"
        />
        <path
          d="M506.8,249.22c0-38.96,32.46-57.71,107.49-66.01c-0.36-22-7.57-43.29-36.07-43.29c-20.2,0-38.6,9.02-52.3,18.04
		l-11.54-20.2c16.23-10.82,40.76-22.73,68.9-22.73c42.56,0,60.96,28.5,60.96,71.78v107.85H619.7l-2.89-20.92h-1.08
		C599.14,287.81,580.02,299,558.02,299C528.8,299,506.8,280.96,506.8,249.22z M614.29,252.1v-47.62
		c-59.16,6.85-78.28,20.2-78.28,42.93c0,19.48,13.35,27.05,30.3,27.05C583.27,274.47,597.34,266.89,614.29,252.1z"
        />
        <path
          d="M699.42,119.36h24.53l2.53,31.74h1.08c12.26-22,30.3-36.07,49.78-36.07c7.94,0,13.35,1.08,18.76,3.61l-6.49,25.61
		c-5.05-1.44-9.02-2.16-15.87-2.16c-14.79,0-32.46,10.46-44.37,40.04v112.54h-29.94V119.36z"
        />
        <path
          d="M830,240.2v-96.67h-25.97v-22.37l27.41-1.8l3.61-56.27h24.89v56.27h47.62v24.17h-47.62v97.39
		c0,21.28,7.21,33.55,27.05,33.55c6.49,0,12.99-1.08,20.2-4.33l6.13,21.28c-9.38,4.33-21.28,7.57-33.19,7.57
		C842.62,299,830,275.19,830,240.2z"
        />
      </g>
      <g className={bottomClassName}>
        <path
          d="M1231.11,259.32V37.84h29.94v223.65c0,9.38,3.97,12.62,8.66,12.62c1.8,0,3.25,0,6.13-0.72l3.97,22.36
		c-4.33,2.16-9.74,3.25-17.31,3.25C1240.13,299,1231.11,284.57,1231.11,259.32z"
        />
        <path
          d="M1318.4,63.81c0-11.54,8.3-19.48,19.84-19.48s19.84,7.94,19.84,19.48c0,11.9-8.3,19.48-19.84,19.48
		S1318.4,75.71,1318.4,63.81z M1323.09,119.36h29.94v175.31h-29.94V119.36z"
        />
        <path
          d="M1412.18,119.36h24.53l2.52,25.25h1.08c16.59-16.59,34.63-29.58,58.8-29.58c37.15,0,53.75,23.81,53.75,68.54v111.1h-29.94
		V187.53c0-32.83-10.1-46.53-33.19-46.53c-17.68,0-30.3,9.02-47.62,26.69v126.97h-29.94V119.36z"
        />
        <path
          d="M1609.5,37.84h29.58v173.14h1.08l73.95-91.62h33.55l-58.44,70.7l66.01,104.61h-32.46l-51.22-84.05l-32.46,37.88v46.17
		h-29.58V37.84z"
        />
      </g>
      <g>
        <g transform="translate(0.000000,3000.000000) scale(0.100000,-0.100000)">
          <path
            className={topClassName}
            d="M668.25,29999.03c-10.92-1.78-26.36-6.89-36.81-12.23c-25.29-13.18-49.75-38.23-61.03-62.69
			c-7.01-15.08-9.86-27.43-10.45-45.71c-0.71-19.47,0.47-28.26,5.82-44.29l3.8-11.16l-7.72-7.24c-4.16-3.92-26.36-24.58-49.16-45.83
			c-22.8-21.25-44.76-41.68-48.68-45.48c-3.92-3.8-22.2-20.3-40.73-36.81c-18.4-16.38-33.36-30.28-33.25-30.75
			c0.24-0.48,8.19-9.62,17.69-20.19c123.13-137.62,164.45-183.21,165.64-183.21c0.71,0,6.29,6.65,12.23,14.84
			c13.42,18.52,26,33.96,40.25,49.16c5.94,6.53,10.92,12.47,10.92,13.18c-0.12,0.59-6.89,8.79-15.2,17.93
			c-58.54,64.95-101.76,113.63-101.76,114.46c0,0.83,42.15,40.61,92.5,87.15c12.11,11.28,12.94,11.75,15.91,10.57
			c29.92-12.59,36.33-13.89,61.27-13.06c14.84,0.47,19.71,1.07,28.38,3.68c21.14,6.29,38.71,16.62,54.5,31.94
			c18.17,17.69,30.28,37.76,36.21,59.72c2.73,10.09,3.09,14.25,3.21,29.56c0,21.37-2.37,32.89-10.57,50.11
			c-13.06,27.79-37.64,52.96-64.12,65.78C718.12,29997.61,688.67,30002.12,668.25,29999.03z M708.38,29925.06
			c20.3-9.62,34.08-30.28,34.08-51.29c0-15.79-6.06-29.21-18.64-40.96c-20.54-19.35-49.28-21.25-71.12-4.75
			c-28.02,21.13-31.58,58.42-8.07,83.12c13.54,14.25,25.29,19.24,43.46,18.64C698.29,29929.45,700.42,29928.86,708.38,29925.06z"
          />
          <path
            className={topClassName}
            d="M1046.07,29671.68c-0.24-0.12-4.99-0.59-10.69-0.83c-5.58-0.36-11.99-0.83-14.13-1.19c-2.14-0.36-8.19-0.95-13.42-1.3
			c-31.58-2.14-59.13-7.12-93.21-16.74c-10.8-2.97-20.66-5.7-21.97-6.06c-3.56-0.95-21.37-7.84-41.56-16.15
			c-29.09-11.88-77.53-37.88-94.4-50.58c-2.26-1.66-5.7-4.16-7.72-5.58c-30.63-21.02-48.8-36.33-78.25-66.25
			c-36.81-37.28-62.22-71-89.76-118.97c-37.88-65.9-59.61-135.6-67.09-214.79c-1.31-14.72-1.78-103.18-2.49-517.93
			c-0.71-489.31-0.71-500.23,1.42-500.35c2.97-0.24,275.59-1.31,394.09-1.54l92.61-0.12l0.36,43.1l0.24,42.98l-3.8,1.78
			c-2.14,0.95-24.46,10.57-49.63,21.25c-25.17,10.8-60.67,26-78.96,33.96c-18.29,7.96-35.62,15.44-38.59,16.62
			c-12.35,4.99-23.27,9.86-24.22,10.81c-1.54,1.42-1.07,820.7,0.59,832.81c10.45,80.74,60.08,152.7,132.63,192.83
			c25.17,13.89,63.41,26.12,91.07,29.21c45.59,4.99,89.17-0.36,127.64-15.55c90.24-35.74,151.74-115.53,162.67-211.23
			c0.71-7,1.19-116,1.19-319.64c0-182.5,0.47-309.07,1.07-309.07c0.59,0,5.58,2.14,11.04,4.63
			c49.63,23.51,102.23,61.98,140.82,103.06c37.52,40.01,60.79,73.62,85.13,123.25c30.87,62.81,47.38,125.86,50.58,192.95
			c1.42,30.28,0.59,196.74-1.07,223.82c-4.04,61.86-23.27,130.97-53.79,193.54c-19.24,39.54-41.68,73.62-76.11,116.12
			c-34.08,41.91-85.49,84.78-141.77,118.02c-32.77,19.47-90.83,44.53-116.24,50.23c-4.75,0.95-13.77,3.32-24.93,6.41
			c-8.07,2.26-25.65,6.17-34.43,7.72c-15.55,2.73-24.93,4.16-26,3.8c-0.59-0.12-3.56,0.24-6.53,0.95
			c-5.34,1.19-19.24,2.26-38.71,3.21C1073.61,29671.56,1046.42,29672.15,1046.07,29671.68z"
          />
          <path
            className={topClassName}
            d="M2015.9,29493.22c-26.48-4.16-52.6-18.52-73.62-40.49c-15.79-16.51-24.58-31.7-30.99-52.96
			c-2.49-8.31-3.21-13.89-3.68-26.83c-0.71-19,0.71-30.16,5.7-44.41c1.9-5.34,3.44-10.09,3.44-10.69s-4.39-5.11-9.86-9.97
			c-10.69-9.5-67.2-60.91-90.83-82.64c-8.07-7.36-15.32-13.42-16.27-13.42c-1.54,0-66.49,28.97-68.87,30.75
			c-0.59,0.47-4.16,2.14-7.72,3.56c-3.56,1.54-22.92,9.86-42.98,18.64c-19.95,8.79-37.16,15.91-38.11,15.91
			c-2.49,0-8.31-15.55-7.72-20.42c0.36-2.14,2.26-11.99,4.27-21.96c7.96-38.47,9.38-56.4,10.45-131.32
			c0.47-28.02,1.19-50.46,1.66-49.87c0.59,0.47,9.86,21.25,20.66,45.95c10.81,24.81,20.19,45.59,20.78,46.19
			c0.71,0.71,22.92-8.67,60.91-25.88c32.89-14.96,61.15-27.67,62.93-28.26c2.85-1.19,3.68-0.71,10.09,5.11
			c16.15,14.84,137.38,125.15,149.73,136.31c9.62,8.67,14.13,11.99,15.55,11.52c16.27-6.29,46.19-8.43,64.35-4.75
			c25.05,4.99,45.71,16.15,64.83,34.79c15.91,15.55,27.31,33.25,33.36,51.89c14.25,43.7,2.85,89.29-30.99,124.32
			c-24.82,25.53-52.72,38.47-85.73,39.54C2029.44,29494.05,2019.82,29493.81,2015.9,29493.22z M2056.04,29419.24
			c20.3-9.62,34.08-30.28,34.08-51.29c0-15.79-6.06-29.21-18.64-40.96c-23.15-21.73-55.21-21.25-77.42,1.07
			c-22.56,22.56-23.27,54.86-1.78,77.3c13.54,14.25,25.29,19.24,43.46,18.64C2045.94,29423.64,2048.08,29423.04,2056.04,29419.24z"
          />
          <path
            className={topClassName}
            d="M338.64,28758.6c-3.56-3.8-13.42-14.6-21.85-24.1c-15.67-17.69-21.25-23.63-47.02-50.46
			c-8.19-8.43-26.36-27.43-40.37-42.15c-13.89-14.72-31.23-32.77-38.47-40.01l-13.06-13.42l-11.87,4.16
			c-14.49,4.99-23.75,6.53-38.71,6.53c-27.78,0-53.19-8.67-75.75-25.88c-25.65-19.71-43.58-48.09-50.11-79.2
			c-1.9-9.03-1.9-33.48,0-42.75c7.01-34.08,28.02-64.12,59.37-85.01c22.44-14.84,43.22-20.78,70.17-19.71
			c19.35,0.71,30.04,3.32,46.9,11.4c21.25,10.09,40.85,27.43,54.74,48.33c15.79,23.75,22.68,51.89,19.59,79.55
			c-1.54,13.3-5.58,28.14-10.33,37.52c-1.54,3.09-2.85,6.53-2.73,7.72c0,2.14,96.53,103.66,99.26,104.49
			c0.71,0.36,14.96-11.99,31.47-27.19c75.04-69.1,100.57-92.38,101.76-92.38c0.95,0,1.31,19.83,1.31,66.97v66.85l-21.02,19.47
			c-56.04,51.77-104.01,95.46-105.32,95.82C345.88,28765.25,342.32,28762.4,338.64,28758.6z M142.48,28526.94
			c26.12-8.19,43.93-36.1,39.66-62.22c-3.09-18.4-15.32-34.55-32.77-43.22c-9.26-4.63-9.5-4.63-23.15-4.63s-13.89,0-23.03,4.63
			c-20.42,10.09-33.96,30.52-33.96,51.18c0,23.04,16.98,46.07,39.78,53.91C118.26,28529.79,132.87,28529.91,142.48,28526.94z"
          />
          <path
            className={bottomClassName}
            d="M1011.99,28405.36c-2.37-0.83-2.37-1.78-2.37-44.05v-43.22l6.89-2.26c3.68-1.19,7.6-2.26,8.55-2.49
			c0.95-0.12,12.23-3.32,24.93-7.01c12.7-3.68,26.6-7.6,30.87-8.79c26.36-7.36,34.43-9.74,39.78-11.28
			c3.32-0.95,30.75-8.67,61.15-17.22c52.72-14.96,59.72-16.86,78.96-22.44c4.63-1.3,9.62-2.61,11.28-2.97
			c1.66-0.36,9.38-2.61,17.22-4.99s18.29-5.34,23.15-6.65c4.87-1.43,8.91-2.49,8.91-2.61s-0.24-165.76-0.59-368.2
			c-0.59-345.64-0.83-368.79-2.73-379.36c-5.34-28.38-11.64-47.73-23.39-71.84c-20.19-41.44-48.92-74.57-87.27-100.33
			c-3.21-2.14-7.48-4.99-9.26-6.17c-8.19-5.58-35.5-18.17-53.19-24.46c-9.74-3.56-30.16-8.55-40.25-9.86
			c-4.87-0.71-11.04-1.43-13.65-1.78c-10.8-1.42-47.97-1.31-58.77,0.24c-28.26,3.8-51.29,10.09-73.85,20.07
			c-71.95,31.58-127.05,96.53-146.28,172.52c-7.72,30.28-7.12,6.53-7.48,303.96c-0.36,240.44-0.59,269.77-2.26,269.77
			c-1.42,0-24.46-11.4-38.59-19.23c-28.14-15.44-65.3-42.63-92.14-67.68c-45.48-42.27-80.03-88.34-107.57-143.43
			c-26.48-52.84-41.32-100.69-50.34-162.67c-1.66-11.4-2.02-26.84-2.37-94.4l-0.47-80.74l2.97-13.06
			c1.66-7.12,4.04-19.47,5.46-27.31c9.62-55.33,31.7-116.72,60.67-168.72c62.22-111.73,165.99-202.33,282.71-246.97
			c21.25-8.07,50.46-17.57,61.51-20.07c0.95-0.12,4.16-0.95,7.12-1.78c2.97-0.83,6.65-1.66,8.31-1.9c3.32-0.59,10.8-2.14,19.59-4.16
			c3.32-0.71,8.31-1.54,11.28-1.78c2.97-0.36,7.01-1.07,8.91-1.54c3.21-0.95,11.52-2.14,23.15-3.21c2.26-0.24,8.43-0.95,13.54-1.54
			c10.21-1.3,13.18-1.42,45.24-1.54c26.72-0.12,49.75,0.95,60.56,2.61c10.33,1.66,12.7,2.02,20.19,2.85
			c3.32,0.48,8.67,1.31,11.87,1.9c3.32,0.59,8.07,1.43,10.69,1.9s5.58,0.95,6.53,1.19c14.96,2.61,67.56,16.98,75.4,20.66
			c2.85,1.3,10.09,4.15,25.53,10.09c27.55,10.69,64.35,30.52,97.72,52.48c113.04,74.56,198.29,195.91,228.92,326.29
			c8.19,34.91,11.16,53.91,14.01,88.46c0.83,9.5,1.54,177.27,2.02,469.37l0.71,454.4l-2.73,0.12c-1.54,0-112.92,0.24-247.45,0.47
			s-268.46,0.59-297.67,0.83C1038.35,28406.07,1013.29,28405.83,1011.99,28405.36z"
          />
          <path
            className={bottomClassName}
            d="M176.56,28120.51c-18.64-3.09-38.47-12.11-56.52-25.65c-33.72-25.41-50.34-58.06-49.99-98.31
			c0-12.59,0.59-17.57,3.21-27.31c9.5-36.45,38.35-71.24,71.48-86.2c20.54-9.38,47.73-12.82,69.34-8.79
			c18.52,3.44,15.32,5.34,33.48-19.59c61.39-84.3,73.5-101.52,73.02-104.01c-0.24-1.42-8.67-16.38-18.76-33.01
			c-20.54-34.31-20.54-34.08-46.54-79.32c-10.33-18.17-18.64-33.72-18.29-34.43c0.36-0.83,25.65-15.91,56.16-33.6
			c30.63-17.57,83.12-47.97,116.72-67.32c33.72-19.47,61.62-34.91,62.1-34.43c0.59,0.59,0.83,25.77,0.71,56.16l-0.36,55.09
			l-49.75,28.73c-27.43,15.91-50.46,29.45-51.29,30.28c-1.19,1.19-0.36,3.44,4.16,11.04c28.14,46.78,63.05,106.27,63.05,107.34
			c0,0.71-2.37,4.39-5.22,8.07c-2.85,3.8-19.47,26.71-36.93,50.82c-17.57,24.22-44.17,61.03-59.37,81.93
			c-15.2,20.9-28.26,39.06-29.09,40.49c-1.31,2.02-0.83,3.92,2.73,13.65c6.65,17.81,8.31,28.26,7.6,48.44
			c-0.59,19.36-3.44,31.46-10.92,47.14c-12.23,25.53-35.5,49.87-58.77,61.62C227.97,28119.79,198.88,28124.31,176.56,28120.51z
			 M210.88,28050.57c14.37-4.39,27.43-16.62,34.91-32.65c3.09-6.53,3.32-8.31,3.32-20.78c-0.12-16.38-2.26-22.92-10.92-33.84
			c-10.33-12.7-24.7-20.42-40.13-21.61c-16.39-1.31-30.04,3.92-42.27,16.15c-22.2,22.32-23.39,53.67-2.85,75.87
			C168.61,28050.69,190.22,28056.98,210.88,28050.57z"
          />
          <path
            className={bottomClassName}
            d="M1652.69,27483.96c-0.36-2.85-1.19-11.4-1.9-19c-1.07-14.13-3.8-35.27-6.06-47.26c-2.97-15.44-5.82-28.5-8.07-37.64
			c-1.42-5.34-2.49-10.69-2.49-11.88s4.39-6.65,9.86-12.23c5.34-5.58,40.13-41.56,77.42-80.03c37.16-38.35,68.15-70.65,68.75-71.6
			c1.07-2.02-0.12-3.32-62.22-65.9c-21.61-21.85-38.23-37.76-39.54-37.76c-1.19,0-4.16,1.07-6.77,2.38
			c-20.78,10.57-57.94,12.59-84.3,4.51c-18.52-5.7-36.45-16.98-52.24-32.89c-17.1-17.22-27.67-34.55-33.96-55.45
			c-3.92-13.06-5.34-39.54-2.85-53.19c4.27-24.22,15.44-45.48,34.2-65.07c38.11-39.89,89.53-51.65,137.5-31.7
			c28.26,11.76,57.47,40.85,69.94,69.7c6.89,15.79,9.38,28.14,9.38,47.14c0.12,18.52-1.78,28.97-7.84,44.88
			c-1.9,4.99-3.32,9.62-3.09,10.21c0.71,1.9,125.62,127.29,149.13,149.72c12.59,11.99,22.68,22.44,22.56,23.15
			c-0.36,1.31-46.66,49.39-218.83,227.02c-25.41,26.36-46.66,47.85-47.02,47.85C1653.76,27488.95,1653.04,27486.7,1652.69,27483.96z
			 M1655.66,27038.23c9.26-4.39,21.49-15.44,26.83-24.34c15.08-25.17,6.53-58.06-19.47-75.4c-10.33-6.89-18.88-9.14-32.53-8.43
			c-14.96,0.59-23.39,4.39-35.38,15.67c-17.22,16.27-22.8,37.4-15.55,58.54c4.39,12.59,16.74,26.36,29.68,33.01
			c8.43,4.28,13.42,5.22,26.12,4.87C1646.51,27041.91,1648.65,27041.43,1655.66,27038.23z"
          />
        </g>
      </g>
      <g>
        <g transform="translate(0.000000,3000.000000) scale(0.100000,-0.100000)">
          <path
            className={bottomClassName}
            d="M9856.19,28387.26c-5.49-1.26-16.15-3.19-23.75-4.45c-7.5-1.16-21.75-4.06-31.67-6.29
			c-176.92-41.12-315.94-170.58-357.75-333.33c-33.88-131.69-0.84-270.92,89.52-377.84c68.3-80.79,166.57-139.62,271.4-162.17
			c31.04-6.68,47.92-9.1,78.64-11.42c16.68-1.26,819.68-2.61,820.42-1.45c0.32,0.48,0.74,111.47,0.95,246.64l0.42,245.77
			l-39.16,0.29l-39.06,0.19l-10.77-35.03c-5.91-19.35-15.31-49.73-20.8-67.54c-12.46-39.87-37.37-120.75-42.65-138.17l-3.91-12.87
			l-321.22,0.1c-216.08,0-324.7,0.39-331.46,1.06c-36.74,3.68-71.57,15.29-102.71,34.45c-54.79,33.48-90.89,87.08-99.54,147.65
			c-2.01,14.61-2.01,39.86,0,54.57c9.82,69.76,57.21,130.82,125.51,161.39c21.53,9.68,40.01,15.29,65.02,19.54
			c9.4,1.55,25.55,1.74,250.5,1.94c158.45,0.19,240.36,0.68,240.36,1.26c0,1.94-15.2,28.74-23.01,40.64
			c-43.91,66.86-105.77,119.3-181.78,154.13c-46.97,21.58-89.52,33.58-144.62,41.03c-10.24,1.36-23.43,1.64-85.5,1.84l-73.36,0.29
			L9856.19,28387.26z"
          />
          <path
            className={topClassName}
            d="M10481.11,28309.66c0-44.12-0.32-133.91-0.74-199.42l-0.63-119.3h38.42h38.42l20.27,43.35
			c32.93,70.25,54.36,115.82,54.79,116.21c0.95,0.97,732.91,0,740.93-0.97c38.21-4.55,75.48-18.48,107.04-40.06
			c87.93-60.28,116.75-170.88,68.09-262.21c-34.1-64.35-102.39-108.37-180.4-116.59c-6.23-0.58-103.13-0.97-284.17-0.97
			c-151.16,0-274.77-0.29-274.77-0.58c0-0.29,2.74-5.61,6.12-11.8c30.82-56.41,74.42-104.21,130.05-142.91
			c37.47-26.03,92.37-51.09,139.66-63.86c36.84-9.87,65.76-14.42,102.08-16.06c26.92-1.16,173.86-0.48,197.93,0.87
			c75.48,4.55,160.03,31.64,227.48,73.05c12.14,7.35,33.15,21.67,42.22,28.64c3.17,2.52,7.49,5.61,9.5,7.06
			c2.01,1.36,9.5,7.55,16.47,13.64c61.75,54.09,110.84,128.49,131.42,199.32c18.79,64.83,23.01,116.69,14.57,181.13
			c-5.17,39.19-14.57,72.38-31.35,109.82c-21.22,47.31-51.3,92.11-86.56,128.69c-11.82,12.29-39.27,36.77-52.46,46.93
			c-38.32,29.32-87.09,56.8-129.1,72.57c-34.94,13.06-75.05,23.12-111.58,28.06c-32.72,4.35-18.9,4.16-488.43,4.93l-445.25,0.68
			V28309.66z"
          />
        </g>
      </g>
    </svg>
  );
};
